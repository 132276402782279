.card-spotlight,.approachstylecard {
    position: relative;
    border-radius: 1.5rem;
    background-color: #111;
    padding: 2rem;
    overflow: hidden;
    --mouse-x: 50%;
    --mouse-y: 50%;
    --spotlight-color: rgba(255, 255, 255, 0.05);
  }
  .approachstylecard{
    background-color: #ececec00;
    border: 1px solid #22222200;
  }
  .card-spotlight::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(circle at var(--mouse-x) var(--mouse-y), var(--spotlight-color), transparent 80%);
    opacity: 0;
    transition: opacity 0.5s ease;
    pointer-events: none;
  }
  
  .card-spotlight:hover::before,
  .card-spotlight:focus-within::before {
    opacity: 0.6;
  }
  