/* Tech.css */
.tech-container {
    min-height: 100vh;
    background: linear-gradient(135deg, #ffffff 0%, #ffffff 100%);
    padding: 2rem;
  }
  
  .tech-wrapper {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .tech-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: rgb(0, 0, 0);
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .tech-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1.5rem;
    padding: 1rem;
  }
  
  .tech-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    text-align: left;
    transition: all 0.3s ease;
  }
  
  .tech-card::before {
    content: '';
    position: absolute;
    inset: -1px;
    border-radius: 1rem;
    padding: 1px;
    background: linear-gradient(45deg, #3b82f6, #f65c5c);
    -webkit-mask: 
      linear-gradient(#fff 0 0) content-box, 
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .tech-card:hover {
    transform: translateY(-5px) scale(1.05);
  }
  
  .tech-card:hover::before {
    opacity: 1;
  }
  
  .tech-card-content {
    position: relative;
  }
  
  .tech-icon {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
  
  .tech-name {
    font-size: 1.25rem;
    font-weight: bold;
    color: rgb(37, 95, 158);
    margin-bottom: 0.5rem;
  }
  
  .tech-description {
    color: #a0aec0;
    margin-bottom: 1rem;
    font-size: 0.9rem;
  }
  
  .tech-category {
    display: inline-block;
    padding: 0.25rem 0.75rem;
    background-color: rgba(55, 65, 81, 0.8);
    color: #cbd5e0;
    border-radius: 9999px;
    font-size: 0.75rem;
  }

.tech-card-content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;  /* Center align all content */
    text-align: center;
  }
  
  .tech-card img {
    width: 180px;  /* Fixed width */
    height: 80px;  /* Fixed height */
    object-fit: contain;  /* Maintain aspect ratio without cropping */
    margin-bottom: 1.5rem;
    transition: transform 0.3s ease;
  }
  
  .tech-card:hover img {
    transform: scale(1.1);  /* Slightly scale up image on hover */
  }
  
  /* Optional: Add a container for the image to control its space */
  .tech-image-container {
    width: 100%;
    height: 100px;  /* Fixed height container */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
  }
  
  /* Adjust spacing for cards with images */
  .tech-card-content img + .tech-name {
    margin-top: 0;  /* Remove top margin when image is present */
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .tech-card img {
      width: 60px;  /* Smaller images on mobile */
      height: 60px;
    }
    
    .tech-image-container {
      height: 80px;  /* Smaller container on mobile */
    }
  }
  /* Media Queries */
  @media (max-width: 768px) {
    .tech-grid {
      grid-template-columns: 1fr;
    }
    
    .tech-container {
      padding: 1rem;
    }
    
    .tech-title {
      font-size: 2rem;
    }
  }