.training-placement-container {
    max-width: 99vw;
    padding: 2rem 1rem;
    align-items: center;
    text-align: center;
    padding: 2rem;
  }
  
  .main-title {
    font-size: 2.5rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 1rem;
    color: #333;
  }
  
  .intro-text {
    font-size: 1.1rem;
    text-align: left;
    margin: 0 auto 2rem;
    color: #666;
  }
  
  .section-title {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
    color: #444;
  }
  
  .training-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    margin-bottom: 3rem;
  }
  
  .training-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    transition: transform 0.3s ease-in-out;
  }
  
  .training-card:hover {
    transform: translateY(-5px);
  }
  
  .program-title {
    font-size: 1.3rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    color: #333;
  }
  
  .program-duration {
    font-size: 0.9rem;
    color: #666;
    margin-bottom: 0.5rem;
  }
  
  .program-description {
    font-size: 1rem;
    margin-bottom: 1rem;
    color: #444;
  }
  
  .program-topics {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .program-topics li {
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
    color: #555;
    display: flex;
    align-items: center;
  }
  
  .program-topics li::before {
    content: "•";
    color: #4a90e2;
    font-size: 1.2rem;
    margin-right: 0.5rem;
  }
  
  .placement-list {
    list-style-type: none;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1rem;
  }
  
  .placement-list li {
    background-color: #f0f4f8;
    border-radius: 8px;
    padding: 1rem;
    font-size: 1rem;
    color: #333;
    display: flex;
    align-items: center;
    transition: background-color 0.3s ease-in-out;
  }
  
  .placement-list li:hover {
    background-color: #e1e8ed;
  }
  
  .placement-list li::before {
    content: "✓";
    color: #4caf50;
    font-size: 1.2rem;
    margin-right: 0.5rem;
  }
  
  @media (max-width: 768px) {
    .training-grid {
      grid-template-columns: 1fr;
    }
  
    .placement-list {
      grid-template-columns: 1fr;
    }
  }


  /* table wala data */
  .tabledabba{
    display:flex;
    flex-wrap:wrap;
    gap:20px;
    width:80%;
  }
  /* .tablebox{
    background-color: ;
  } */
  table {
    width: 90%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 18px;
    text-align: left;
  }
  
  th, td {
    padding: 12px;
    border-bottom: 1px solid #ddd;
  }
  
  th {
    background-color: #f4f4f4;
  }
  
  tr:hover {
    background-color: #f1f1f1;
  }
  