body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    margin: 0;
    padding: 0;
    padding-top: 80px;
  }
  .header {
    background-color: rgba(255, 255, 255, 0.95);
    backdrop-filter: blur(10px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out; 
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000; 
  }
  .header-scrolled {
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  .upardabba{
    background-color: #d1f9f6;
    display: 'flex';
    flex-wrap: 'wrap'; 
    gap: '1rem'; 
    width: '60%'; 
    justify-content: 'center'; 
    align-items: 'center'; 
    margin: '0 auto' ;
    box-shadow:" 0 0 10px rgba(0,0,0,0.5)";
    padding:"20px";
    border-radius:"30px";
    padding-top:'60px';
    padding-bottom:'60px'
  }

  .upardabba .service-card, .nichedabba .service-card{
    background-color: #ededee;
  }
  .nichedabba{
    background-color: #d1f9f6;
  }
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  .container .content-container{
    background-color: #983d3d;
  }
  
  .header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .logo-container {
    display: flex;
    align-items: center;
    margin-left: -100px;
  }
  
  .logo {
    width: 50px;
    height: 50px;
    margin-right: 120px;
  }
  
  .company-name {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  /* Desktop nav is visible only on large screens */
  .desktop-nav {
    display: none;
  }
  
  .nav-link {
    color: rgb(0, 0, 0);
    text-decoration: none;
    margin-left: 20px;
    transition: color 0.3s;
    cursor: pointer;
    font-size: 18px;
  }
  
  .nav-link:hover {
    color: #63b3ed;
  }
  
  /* Mobile button visible on smaller screens */
  .mobile-menu-button {
    background: none;
    border: none;
    color: rgb(0, 0, 0);
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .mobile-nav {
    display: none;
    background-color: #2d3748;
    padding: 1rem;
  }
  
  /* Mobile links */
  .mobile-nav-link {
    display: block;
    color: white;
    text-decoration: none;
    padding: 0.5rem 0;
  }

  @media (min-width: 768px) {
    .desktop-nav {
      display: flex;
    }
  
    .mobile-menu-button {
      display: none;
    }
  }
  
  @media (max-width: 768px) {
    .mobile-nav {
      display: block;
    }
    .logo-container {
      display: flex;
      align-items: center;
      margin-left: 0px;
    }
  }
  
  .content-section {
    padding: 3rem 0;
  }
  
  .content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .content-text {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .content-text h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .content-image img {
    max-width: 100%;
    height: auto;
    border-radius: 0.5rem;
  }


.content-section {
  padding: 4rem 2rem;
}

.content-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4rem;
}

.content-text {
  flex: 1;
  padding-right: 2rem;
}
.content-image {
  flex: 1;
  text-align: center;
}

.content-image img {
  max-width: 100%;
  height: auto;
}

.cta-button {
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  background-color: #1c63b3ab;
}
.cta-button-service{
  background-color: #d4dbe3ab;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 20px;
}
.service-portfolio {
  padding: 2rem 1rem;
  background-color: #ffffff;
  overflow: hidden;
}

.service-portfolio h2 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 4rem;
  color: #333;
  font-weight: 700;
}

.slick-slider {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.slick-slide {
  padding: 0 10px;
  transition: all 0.5s ease;
  opacity: 0.6;
  transform: scale(0.8) rotate(-5deg); /* Add slight rotation for side slides */
  z-index: 0; /* Ensure side slides are below center */
}

.slick-center {
  opacity: 1;
  transform: scale(1) rotate(0deg); /* Center slide is upright and larger */
  z-index: 1; /* Center slide is above others */
}

.slick-center .service-box {
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
}

.service-box {
  background-color: #fff;
  border-radius: 15px;
  padding: 2rem;
  text-align: center;
  position: relative;
  overflow: hidden;
  min-height: 550px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 95vw !important;
  max-width: 900px;
  margin: 0 auto;
  transition: transform 0.5s ease, opacity 0.5s ease;
}

/* Overlap effect for adjacent slides */
.slick-slide:not(.slick-center) {
  margin: 0 -20px; /* Negative margin to create overlap */
}

/* Ensure center slide is not affected by negative margin */
.slick-center {
  margin: 0;
}

.icon-wrapper {
  margin: 1.5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.2);
  margin-left: auto;
  margin-right: auto;
}

.service-box h3 {
  font-size: 1.5rem;
  margin: 1rem 0;
  color: #fff;
  font-weight: 600;
}

.service-box p {
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 1.5rem;
  padding: 0 1rem;
}

.dabbas {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 1rem;
  padding: 0 1rem;
}

.services, .products {
  flex: 1;
  text-align: left;
}

.services p, .products p {
  font-size: 1.1rem;
  color: #fff;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.services ul, .products ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.services li, .products li {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 0.5rem;
}

.services li svg, .products li svg {
  margin-right: 0.5rem;
  color: #fff;
}

.read-more-btn {
  cursor: pointer;
  margin-top: 1.5rem;
  transition: transform 0.2s ease;

}

.read-more-btn img {
  width: 120px;
  height: auto;
}

/* Slick Arrow Styles */
.slick-prev, .slick-next {
  width: 40px;
  height: 40px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  z-index: 1;
}

.slick-prev:before, .slick-next:before {
  font-size: 24px;
  color: #fff;
}

.slick-prev {
  left: 10px;
}

.slick-next {
  right: 10px;
}

.slick-dots {
  bottom: -40px;
}

.slick-dots li button:before {
  font-size: 12px;
  color: #666;
}

.slick-dots li.slick-active button:before {
  color: #333;
}

@media (max-width: 768px) {
  .service-portfolio h2 {
    font-size: 2rem;
  }

  .service-box {
    min-height: 500px;
    width: 85vw !important;
  }

  .dabbas {
    flex-direction: column;
  }

  .slick-slide:not(.slick-center) {
    margin: 0;
  }
}

.approachdabba {
  width: 100%;
  padding: 30px;
  max-width: 1400px;
  margin: 0 auto;
}

.approachdabba h2 {
  font-size: 2rem;
  color: #333;
  text-align: center;
  margin-bottom: 30px;
}

.approachcards-container {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  margin-top: 4rem;
}

.approachcard {
  width: 29%;
  padding: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  background-color: #fff;
  text-align: left;
  display: flex;
  flex-direction: column;
  background-image: url('../../assets//imgs/box_bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.approachcard h3 {
  font-size: 1.3rem;
  margin-bottom: 15px;
  color: #2563eb;
}

.approachcard ul {
  list-style-type: none;
  padding-left: 0;
}

.approachcard li {
  margin-bottom: 10px;
  font-size: 0.9rem;
  color: #555;
  position: relative;
  padding-left: 20px;
}

.approachcard li::before {
  content: "•";
  color: #2563eb;
  font-size: 1.2em;
  position: absolute;
  left: 0;
  top: -2px;
}

.card-icon {
  width: 30px;
  height: 30px;
  color: #2563eb;
  margin-bottom: 15px;
}

@media (max-width: 1024px) {
  .approachcards-container {
    flex-direction: column;
  }

  .approachcard {
    width: 80%;
    margin-bottom: 20px;
  }

  .approachcard:last-child {
    margin-bottom: 0;
  }
}

@media (max-width: 768px) {
  .approachdabba {
    padding: 20px;
  }

  .approachdabba h2 {
    font-size: 1.7rem;
  }

  .approachcard h3 {
    font-size: 1.2rem;
  }

  .approachcard li {
    font-size: 0.85rem;
  }
}
h2 {
  text-align: center;
  margin-bottom: 40px;
  font-size: 2rem;
  color: #333;
}

.cybersecurity-assessment {
  padding: 2rem;
  background-color: #ffffff;
}

.assessment-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  text-align: center;
  color: #2563eb;
}

.card-container {
  display: flex;
  justify-content: center;
  gap: 1.8rem;
  margin-top: 10rem;
}

.assessment-card {
  background-color: white;
  padding: 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 12rem;
  background-image: url('../../assets//imgs/box_bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.card-icon {
  width: 3rem;
  height: 3rem;
  color: #3b82f6;
  margin-bottom: 1rem;
}

.card-title {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  text-align: center;
}

.card-description {
  color: #4b5563;
  text-align: center;
}

@media (max-width: 768px) {
  .card-container {
    flex-direction: column;
    align-items: center;
  }

  .assessment-card {
    width: 100%;
    max-width: 20rem;
  }
}

@media (max-width: 768px) {
  .content-container {
    flex-direction: column;
  }

  .content-text {
    padding-right: 0;
    margin-bottom: 2rem;
  }

  .services-boxes {
    flex-direction: column;
  }
}
  
  .contact-form-section {
    background-color: #f7fafc;
    padding: 3rem 0;
    color: #333;
  }
  
  .contact-form-section .container {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    background-color: #ffffff;
    border-radius: 30px;
    display: flex;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    margin-top: 7rem;
  }
  
  .contact-info {
    flex: 1;
    padding: 40px;
    background-color: #9acaf2a0;
    color: rgb(0, 0, 0);
    border-radius: 20px;
  }
  
  .contact-info h2 {
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }
  
  .contact-info h3, .contact-info h4 {
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
  }
  
  .contact-info p {
    margin-bottom: 0.5rem;
  }
  
  .contact-details {
    margin-top: 1rem;
  }
  
  .contact-details p {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .contact-form {
    flex: 1;
    padding: 40px;
  }
  
  .form-group {
    margin-bottom: 1rem;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 0.5rem;
    color: #333;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.25rem;
    background-color: rgba(255, 255, 255, 0.8);
  }
  
  .uploadbtn {
    color: #000000;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    width: auto;
    padding: 0.5rem 1rem;
    gap: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .uploadbtn:hover {
    background-color: #ffffff;
  }
  
  .submit-button {
    background-color: #9acaf2;
    color: white;
    border: none;
    padding: 0.75rem 1rem;
    font-size: 1rem;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 20px;
    width: 100%;
  }
  
  .submit-button:hover {
    background-color: #3182ce;
  }
  
  @media (max-width: 768px) {
    .contact-form-section .container {
      flex-direction: column;
    }
    
    .contact-info, .contact-form {
      width: 70%;
    }
  }

  
  .footer {
    background-color: #a0deff94;
    color: white;
    padding: 1rem 0 1rem;
    position: relative;
    overflow: hidden;
    width: 100vw;
  }
  
  .footer::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    background: linear-gradient(90deg, #4F46E5, #10B981);
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 40px;
  }
  
  .footer-section {
    flex: 1;
    min-width: 200px;
  }
  
  .footer-logo {
    max-width: 250px;
    margin-bottom: 1rem;
  }
  
  .footer-section h3 {
    font-size: 1.2rem;
    margin-bottom: 1.6rem;
    position: relative;
    padding-bottom: 10px;
    margin-top: -1rem;
  }
  
  .footer-section h3::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 50px;
    height: 2px;
    background: linear-gradient(90deg, #4F46E5, #10B981);
  }
  
  .footer-section ul {
    list-style-type: none;
    padding: 0;
  }

  .footer .contact-info{
    background-color: #00000000;
  }
  
  .footer-section ul li {
    margin-bottom: 0.8rem;
  }
  .footer-section h3,.footer-section p{
    color: rgb(0, 0, 0);
  }
  .footer-section a {
    color: #000000;
    text-decoration: none;
    transition: color 0.3s, transform 0.3s;
    display: inline-block;
  }
  
  .footer-section a:hover {
    color: #ffffff;
    transform: translateX(5px);
  }
  .nav-link.active,.mobile-nav-link.active {
    background-color: #80808042;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 5px;
}
  
  .quick-links li a::before {
    content: '→';
    margin-right: 5px;
    transition: margin-right 0.3s;
  }
  
  .quick-links li a:hover::before {
    margin-right: 10px;
  }
  .contact-info li, .social-icons li {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #000000;
  }
  
  .social-icons a {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .social-icons svg {
    transition: transform 0.3s;
  }
  
  .social-icons a:hover svg {
    transform: scale(1.2);
  }
  
  .footer-bottom {
    text-align: center;
    margin-top: 3rem;
    padding-top: 1rem;
    border-top: 1px solid #2d3748;
    color: #000;
  }
  .footerlogodabba{
    margin-left: -4rem;
  }
  
  @media (max-width: 768px) {
    .footer-content {
      flex-direction: column;
    }
  
    .footer-section {
      margin-bottom: 2rem;
    }
    .footerlogodabba{
      margin-left: 0rem;
    }
  }

  .video-carousel-container {
    width: 100%;
    margin: -60px auto;
    overflow: hidden;
  }
  
  .video-slide {
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    position: relative;
    background-color: #000000;
  }
  
  .video-slide video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  @media screen and (max-width: 768px) {
    .carousel .control-arrow {
      display: none; /* Hide arrows on mobile */
    }
    
    .video-slide {
      padding-bottom: 75%; /* 4:3 Aspect Ratio for mobile */
    }
    .video-carousel-container {
      margin: 0 auto;
      overflow: hidden;
      margin-left: 20px;
    }
  }
  
  /* Ensure carousel dots are visible */
  .carousel .control-dots {
    bottom: 10px;
  }
  
  .carousel .control-dots .dot {
    background: #fff;
    box-shadow: 0 0 5px rgba(0,0,0,0.5);
  }


  .file-upload-label {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    border: 1px solid #e2e8f0;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .file-upload-label:hover {
    background-color: #edf2f7;
  }
  
  .file-upload-label input[type="file"] {
    display: none;
  }

  .success-message {
    color: #2f855a;
    background-color: #c6f6d5;
    border: 1px solid #9ae6b4;
    padding: 10px;
    border-radius: 4px;
    margin-top: 10px;
  }
  
  .error-message {
    color: #c53030;
    background-color: #fed7d7;
    border: 1px solid #feb2b2;
    padding: 10px;
    border-radius: 4px;
    margin-top: 10px;
  }


  .dropdown {
    position: relative;
  }
  
  .dropdown-menu {
    display: none;
    position: absolute;
    z-index: 9999;
    top: 100%;
    left: 0;
    background-color: rgb(165, 111, 111);
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    padding: 10px;
    width: 200px;
  }
  
  .dropdown:hover .dropdown-menu {
    display: grid;
  }
  
  .mobile-dropdown {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
  }
  
  .mobile-nav-link {
    cursor: pointer;
  }
  .contacticons{
    align-items: center;
    justify-content: center;
    flex-direction: row;
    align-content: center;
  }
  
  @media (min-width: 768px) {
    .desktop-nav {
      display: flex;
    }
  
    .mobile-menu-button {
      display: none;
    }
  
    .content-container {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  
    .content-text {
      text-align: left;
      margin-bottom: 0;
      margin-right: 2rem;
      flex: 1;
    }
  
    .content-image {
      flex: 1;
    }
  }
  .dabbas {
    gap: 30px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  
  .dabbas .services, .dabbas .products {
    background-color: rgba(255, 255, 255, 0.425);
    border-radius: 20px;
    width: 250px;
    text-align: left;
    transition: all 0.3s ease;
    padding-left: 20px;
  }
  
  .dabbas .services p, .dabbas .products p {
    font-size: 1.2rem;
    margin-bottom: 10px;
    color: #ffffff;
  }
  
  .dabbas ul {
    list-style-type: none;
    padding: 0;
  }
  
  .dabbas ul li {
    font-size: 1rem;
    margin: 10px 0;
    color: #ffffff;
  }
  
  .google-map-container {
    width: 100%;
    max-width: 1200px;
    margin: 2rem auto;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }