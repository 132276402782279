.services-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 4rem 2rem;
    text-align: center;
  }
  
  .services-title {
    font-size: 3.5rem;
    color: #333;
    margin-bottom: 1rem;
  }
  
  .services-subtitle {
    font-size: 2.2rem;
    color: #3fa9d7;
    margin-bottom: 2rem;
    font-weight: bold;
  }
  
  .services-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    margin-bottom: 4rem;
    margin-top: 1rem;
  }
  
  .service-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    text-align: left;
    transition: all 0.3s ease;
  }
  
  .service-icon {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .service-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 1rem;
  }
  
  .service-card h3 {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 1rem;
  }
  
  .service-card p {
    color: #666;
    margin-bottom: 1.5rem;
  }
  
  .learn-more-btn {
    background-color: transparent;
    color: #3182ce;
    border: none;
    padding: 0.5rem 0;
    font-size: 1rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: all 0.3s ease;
  }
  
  .learn-more-btn svg {
    margin-left: 0.5rem;
  }
  
  .cta-container {
    background-color: #f7fafc;
    border-radius: 10px;
    padding: 3rem 2rem;
    margin-top: 4rem;
  }
  
  .cta-container h2 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 1.5rem;
  }
  
  .cta-button {
    background-color: #3182ce;
    color: #fff;
    border: none;
    padding: 0.8rem 2rem;
    font-size: 1.1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .cta-button:hover {
    background-color: #2c5282;
  }
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 110px;
    z-index: 9999;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 80%;
    max-height: 80%;
    overflow-y: auto;
    text-align: left;
  }
  
  .modal-image {
    max-width: 100vw;
    height: 450px;
  }
  
  .close-modal-btn {
    background-color: transparent;
    color: #3182ce;
    border: none;
    padding: 0.5rem 0;
    font-size: 1rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: all 0.3s ease;
  }
  @media (max-width: 768px) {
    .services-grid {
      grid-template-columns: 1fr;
    }
  }