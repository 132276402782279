.products-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem 1rem;
  }
  
  .products-title {
    font-size: 3.5rem;
    font-weight: bold;
    text-align: center;
    color: #000000;
    margin-top: 1rem;
  }
  
  .products-intro {
    font-size: 0.5rem;
    text-align: center;
    max-width: 800px;
    color: #3fa9d7;
  }
  
  .products-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
  }
  
  .product-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease-in-out;
  }
  
  .product-card:hover {
    transform: translateY(-5px);
  }
  
  .product-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .product-name {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 1rem;
    color: #333;
  }
  
  .product-description {
    font-size: 1rem;
    margin: 0 1rem 1rem;
    color: #666;
  }
  
  .product-features {
    list-style-type: none;
    padding: 0 1rem 1rem;
    margin: 0;
  }
  
  .product-features li {
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
    color: #444;
    display: flex;
    align-items: center;
  }
  
  .product-features li::before {
    content: "•";
    color: #4a90e2;
    font-size: 1.2rem;
    margin-right: 0.5rem;
  }
  
  @media (max-width: 768px) {
    .products-grid {
      grid-template-columns: 1fr;
    }
  }