.loading-container {
    position: fixed;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    background-color: #fff; /* Or any background color you prefer */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Make sure it's on top of everything */
  }
  
  .loading-video {
    max-width: 100vw;
    max-height: 100vh;
  }